import React from "react";

const Error = () => {
	return (
		<div className='error'>
			<h2>404 NOT FOUND</h2>
		</div>
	);
};

export default Error;
